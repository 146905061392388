<template>
  <!-- // data只接受对象类型，数据变化时会重新发送 -->
  <page-container type="fullframe">
    <micro-app
      style="height: 100%;"
      name="micro"
      :url='getUrl()'
      baseroute='/micro'
      disableScopecss
      :data='dataForChild'
      @datachange='handleDataChange'
      @created='created'
      @beforemount='beforemount'
      @mounted='mounted'
      @unmount='unmount'
      @error='error'
    ></micro-app>
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import microApp from '@micro-zoe/micro-app'
export default {
  name: 'micro-container',
  watch: {
    // 触发子应用路由改变事件
    '$route.fullPath': {
      immediate: true,
      handler (toPath, fromPath) {
        if (toPath.startsWith('/micro')) {
          setTimeout(() => {
            microApp.setData('micro', { path: toPath })
          })
        }
      }
    }
  },
  computed: {
    ...mapState('account', ['loginInfo', 'userInfo']),
    ...mapState('settings', ['theme']),
    dataForChild () {
      if (this.isCreated) {
        return {
          type: 'userInfo',
          loginInfo: this.loginInfo,
          userInfo: this.userInfo,
          theme: this.theme
        }
      } else {
        return undefined
      }
    }
  },
  data () {
    return {
      isCreated: false
    }
  },
  methods: {
    created () {
      console.log('micro-app元素被创建')
    },
    beforemount () {
      console.log('即将被渲染')
    },
    mounted () {
      this.isCreated = true
      console.log('已经渲染完成')
    },
    unmount () {
      console.log('已经卸载')
    },
    error () {
      console.log('渲染出错')
    },
    getUrl () {
      if (process.env.NODE_ENV === 'production') {
        return location.origin + '/micro'
      } else {
        return 'http://localhost:8001/micro'
      }
    },
    handleDataChange (e) {
      const data = e.detail.data
      if (data.type == 'logOut') {
        this.$store.dispatch('account/clearAcountInfo').then(() => {
          this.$router.push('/login')
        })
      }
    }
  }
}
</script>

<style>
</style>
